import * as React from 'react';
import * as StringTool from '../tools/StringTool';
import { getImgPath } from '../tools/StringTool';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Grid, Box, Stack, Typography } from '@mui/material';
import Payment_SFD from '../components/Payment_SFD';
import Payment_OL from '../components/Payment_OL';

import LoadingComponent from '../components/LoadingComponent';
import ToastComponent from '../components/ToastComponent';

export default function PaymentPage() {

  const { state, search } = useLocation();
  const [page, setPage] = React.useState(null);
  const [paymentType, setPaymentType] = React.useState(null);
  const testMd = {
    gameId: 'overlord',
    name: 'Lord of Nazarick',
    appKey: 'L7xAj3jF9WHGLd75',
    appChannelKey: 'b0q1g4hxj73qw644',
    icon: 'overlord_icon.png'
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const gameId = searchParams.get('gameId');
    let productMd = StringTool.searchGame(gameId);

    //OL测试代码
    if (gameId == 'overlord') {
      productMd = testMd;
    }

    setPaymentType(searchParams.get('paymentType'));

    if (productMd == null) {
      setPage('error');
    } else {
      StringTool.saveSessionString(StringTool.GameId, gameId);
      StringTool.saveSessionString(StringTool.AppKey, productMd.appKey);
      StringTool.saveSessionString(StringTool.AppChannelKey, productMd.appChannelKey);
      setPage(gameId);
    }
  }, []);

  return (
    <Stack>
      {page == 'error' && <Error />}
      {page == 'sfd' && <Payment_SFD paymentType={paymentType} />}
      {page == 'overlord' && <Payment_OL paymentType={paymentType} />}

      <LoadingComponent />
      <ToastComponent />
    </Stack>
  );
}


function Error() {
  return (
    <Stack sx={{
      height: '100vh',
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Box component={'img'}
        src={getImgPath('error_icon.png')}
        sx={{
          width: '90px',
          mb: 2,
        }} />
      <Typography sx={{
        fontSize: 17
      }}>
        Missing params, please check !
      </Typography>
    </Stack>
  );
}