import * as React from 'react';
import * as StringTool from '../tools/StringTool';
import { getImgPath } from '../tools/StringTool';
import BannerItem from '../components/BannerItem';
import { useNavigate } from "react-router-dom";
import { Grid, Box, Stack, Typography } from '@mui/material';

export default function HomePage() {


  React.useEffect(() => {
    document.title = "APLUS GAME PAYMENT";
    StringTool.updateICO('favicon.ico');

    document.body.style.backgroundColor = '#131B3D';
    //重置
    StringTool.saveSessionString(StringTool.AppKey, '');
    StringTool.saveSessionString(StringTool.AppChannelKey, '');
  }, []);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        userSelect: 'none',
      }}>

      <BannerItem />

      <Grid container
        spacing={{ xs: 1, sm: 2 }}
        sx={{
          maxWidth: '1100px',
          px: { xs: 2, sm: 5 },
          pb: 13,
        }} >
        {
          StringTool.gameList().map((data, i) =>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={i}
              sx={{
                mt: 3,
              }}>
              <GameItem data={data} />
            </Grid >
          )
        }
      </Grid>
    </Stack>
  );
}

function GameItem(props) {

  const navigate = useNavigate();

  function gameTap() {
      navigate(`/paymentList?gameId=${props.data.gameId}`);
  }

  return (
    <Stack
      onClick={gameTap}
      component={'div'}
      sx={{
        color: '#EAEBEF',
        cursor: 'pointer',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        bgcolor: '#18214B',
        overflow: 'hidden',
        p: 0,
        '&:hover': {
          bgcolor: '#20295B',
        },
      }}>

      <Box component={'img'}
        src={getImgPath(props.data.icon)}
        sx={{
          width: 1,
          height: '300px',
          objectFit: 'cover',
        }} />

      <Typography sx={{
        textAlign: 'center',
        my: 1,
        fontSize: 16
      }}>
        {props.data.name}
      </Typography>

    </Stack>
  );

}

